<template>
  <div class="notification__customize">
    <Redirect class="" title="Notifictions" route="website.settings.notifications" />
    <v-row>
      <v-col cols="8">
        <p class="heading font-weight-bold mb-0">Customize email templates</p>
      </v-col>
      <v-col cols="4" class="text-right">
        <v-btn class="px-14" color="primary">
          <span>Save</span>
        </v-btn>
      </v-col>
      <v-col cols="8">
        <div class="elevation-form pa-5">
          <p>email template</p>
        </div>
      </v-col>
      <v-col cols="4">
        <div class="elevation-form pa-5">
          <div class="pb-5">
            <h3 class="pb-2">Logo</h3>
            <UploadOneImage :image="{ src: logo.url }" @callback="getImageUpLoad" />
          </div>
          <div class="logo-width">
            <h3 class="pb-2">Logo width (pixels)</h3>
            <v-text-field outlined type="number" v-model="logo.width"></v-text-field>
            <div class="btn-input">
              <v-icon size="18px" class="icon-btn-input" @click="addWidth">fas fa-chevron-up</v-icon>
              <v-icon size="18px" class="icon-btn-input" @click="subWidth">fas fa-chevron-down</v-icon>
            </div>
          </div>
          <div class="input-color">
            <h3 class="pb-2">Color</h3>
            <v-row class="color">
              <v-col cols="12">
                <v-text-field outlined type="text" v-model="logo.color"></v-text-field>
              </v-col>
              <v-col cols="3" class="icon-color pa-3  px-4 text-center">
                <v-icon
                  :color="logo.color"
                  size="30px"
                  class="text-center"
                  @click="isOpenColorPicker = !isOpenColorPicker"
                >
                  fas fa-rectangle-landscape
                </v-icon>
              </v-col>
            </v-row>
            <v-color-picker
              v-model="logo.color"
              width="250px"
              class="ma-2 color-picker"
              :class="{ 'd-none': !isOpenColorPicker }"
              show-swatches
            >
            </v-color-picker>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Redirect from '@/components/RedirectTo';
export default {
  components: {
    Redirect,
  },
  data() {
    return {
      logo: {
        width: 170,
        color: '#B35454',
        url: '',
      },
      isOpenColorPicker: false,
    };
  },
  methods: {
    addWidth() {
      this.logo.width = this.logo.width + 1;
    },
    subWidth() {
      if (this.logo.width > 0) {
        this.logo.width = this.logo.width - 1;
      }
    },
    getImageUpLoad(image) {
      this.logo.url = image.src;
    },
  },
};
</script>
<style lang="scss" scoped>
.heading {
  font-size: 32px;
}
.logo-width {
  position: relative;
}
.btn-input {
  display: grid;
  width: 20px;
  height: 52px;
  background-color: #ccc;
  position: absolute;
  bottom: 32px;
  right: 20px;
}
.icon-btn-input:hover,
.icon-btn-input:active {
  background-color: rgb(141, 141, 141);
  cursor: pointer;
}
.color {
  position: relative;
}
.icon-color {
  position: absolute;
  top: 12px;
  right: 0px;
  background-color: #ccc;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  border: 1px solid #ccc;
  z-index: 1000;
}
.icon-color-open:hover {
  cursor: pointer;
}
.input-color {
  position: relative;
}
.color-picker {
  position: absolute;
  top: 100px;
}
</style>
